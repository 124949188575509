/*
------------
Variables
------------
*/

:root {
	/*colors*/
	--vooter-blue: #348ef8;
	--dark-blue: #3277fe;
	--dark-blue2: #2360d8;
	--light-blue1: #699cff;
	--light-blue3: #f0f5ff;
	--light-blue4: #69d2ff;
	--light-blue5: #f0f0f0;
	--light-grey: rgb(216, 216, 216);
	--vooter-orange: #f98a24;
	--vooter-orange-dark: #f47700;
	--vooter-green: #6acf8c;
	--vooter-green-dark: #56b174;
	--vooter-pink: #f06989;
	--clr-white: #fff;
	--dark-grey: #a0a0a0;
	--bg-modal: rgba(0, 0, 0, 0.5);
	--general: #3d3d3d;
	--transition: all 0.4s linear;
	--spacing: 0.03rem;
	--radius: 10px;
	--light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
	--dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
	/* --max-width: 500px; */
	--max-height: 100%;
	--fsui: 1rem;
}

/*
---------------
Global Styles
---------------
*/

*,
::after,
::before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

#root {
	height: 100%;
}

html {
	font-size: 16px;
	max-width: var(--max-width);
	margin: 0 auto;
	/* background: rgb(45, 53, 185); */
	background: rgb(255, 255, 255);
	height: 100%;
	display: flex;
}

body {
	font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
		Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	background: var(--clr-white);
	color: var(--general);
	font-size: 0.875rem;
	height: 100%;
	flex: 1;
	overscroll-behavior: none;
}

ul {
	list-style-type: none;
}
a {
	text-decoration: none;
}

a:visited {
	color: unset;
}

p {
	font-size: 0.8rem;
}

h1,
h2,
h3,
h4,
h5 {
	letter-spacing: var(--spacing);
	margin: 0;
}
h1 {
	font-size: 2.5rem;
}
h2 {
	font-size: 2rem;
}
h3 {
	font-size: 1.5rem;
}
h4 {
	font-size: 1.25rem;
}
h5 {
	font-size: 1rem;
}
h6 {
	font-size: 0.9rem;
}

hr {
	border: none;
	border-top: 1px solid var(--clr-grey-8);
}

.section {
	padding: 5rem 0;
}

.section-center {
	width: 90vw;
	margin: 0 auto;
	/* max-width: var(--max-width); */
}

.btn {
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	max-width: 21.25rem;
	height: 3.125rem;
	border-radius: var(--radius);
	transition: 0.3s all;
	font-size: var(--fsui);
}

.btn:focus,
.btn:active,
.btn:hover,
.allhover {
	filter: hue-rotate(25deg);
}

.center-fl {
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-overlay {
	background: var(--bg-modal);
	width: 100vw;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 50;
}

textarea:focus,
input:focus,
select:focus {
	outline: none;
}
